import React, { useState } from 'react';
import Style from "../../assets/styles/ModuleStyle/course.module.css"
import LogoSlider from '../Elements/LogoSlider'
import ReCAPTCHA from "react-google-recaptcha";


export default function TopForm({data}) {

  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    email: '',
    mobile: '',
    course: '',
  });
  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptcha = (value) => {
    const timeout = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("ReCAPTCHA Timeout")), 5000) // 5 seconds
    );
  
    Promise.race([
      new Promise((resolve) => resolve(!!value)),
      timeout,
    ])
      .then((result) => setIsVerified(result))
      .catch((error) => console.error("ReCAPTCHA Error:", error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isVerified) {
      alert("Please verify the CAPTCHA to proceed.");
      return;
    }
  };
  return (
    <div className={`${Style.specialization_wrap} mt-md-4 mt-2`}>
        <div className="container">
          <div className={`${Style.special_boxes} row`}>
            <div className="col-lg-5">
              <div className="consult-details">
                <LogoSlider />
                <div className={`${Style["spec-title"]} ${Style.block}`}>
                  <h4>
                    On a single platform, compare the best colleges in India{" "}
                  </h4>
                </div>
                <div className="ShortDesContent">
                  <h5 className={`${Style["main-head"]} ${Style.seotag}`}>
                    {" "}
                    BBA Distance Education{" "}
                  </h5>
                  <div
                    className={`${Style["spec-table"]}  mt-3 table-responsive`}
                  >
                    <table
                      className={`${Style.table} ${Style["table-bordered"]} table table-bordered`}
                    >
                      <tbody>
                        <tr>
                          <th>
                            <i className="uil uil-star" /> No. of Universities
                          </th>
                          <td> 21</td>
                        </tr>
                        <tr>
                          <th>
                            <i className="uil uil-star" /> Course Duration{" "}
                          </th>
                          <td>3 Years</td>
                        </tr>
                        <tr>
                          <th>
                            <i className="uil uil-star" /> Eligibility{" "}
                          </th>
                          <td>12th Pass Out</td>
                        </tr>
                        <tr>
                          <th>
                            <i className="uil uil-star" /> Learning Mode{" "}
                          </th>
                          <td>Online </td>
                        </tr>
                        <tr>
                          <th>
                            <i className="uil uil-star" /> EMI Facility{" "}
                          </th>
                          <td>Available</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div
                id="general-from-wrapper"
                className={Style["general-from-wrapper"]}
              >
                <div
                  className={`${Style["gsc-title-wrap"]} gsc-title-wrap text-center`}
                >
                  <h2
                    className={`${Style["modal-title"]} modal-title w-100 font-weight-bold`}
                  >
                    {" "}
                    Find The Right Course &amp; University
                  </h2>
                  <h3 className="linergrade modal-title"> Online BBA</h3>
                  <ul className="d-flex justify-content-center flex-md-row flex-sm-column list-unstyled mt-2 lead-form-lead-point">
                    <li className="mx-1">
                      <i className="uil uil-shield-check" /> Get Approved
                      University
                    </li>
                    <li className="mx-1">
                      <i className="uil uil-shield-check" /> 100% Placement
                      Assistance
                    </li>
                  </ul>
                </div>
                <div className={`consult-form ${Style["general_form-Wrap"]}`}>
                <form onSubmit={handleSubmit} className="general_form">
                  <div className={`${Style["gererl-box"]} d-flex justify-content-between form-group mb-4`}>
                    <div className={`${Style["form-check"]} form-check col mr-2`}>
                      <div className="radio_wrap">
                        <input
                          className={`${Style["form-check-input"]} form-check-input`}
                          required
                          type="radio"
                          name="gender"
                          value="Male"
                          onChange={handleChange}
                        />
                        <label className="form-check-label">
                          <i className="fa fa-male" aria-hidden="true" /> Male
                        </label>
                      </div>
                    </div>
                    <div className={`${Style["form-check"]} form-check col ml-2`}>
                      <div className="radio_wrap">
                        <input
                          className={`${Style["form-check-input"]} form-check-input`}
                          required
                          type="radio"
                          name="gender"
                          value="Female"
                          onChange={handleChange}
                        />
                        <label className="form-check-label">
                          <i className="fa fa-female" aria-hidden="true" /> Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={Style["gererl-box"]}>
                    <div className={`${Style.form_title} form_title`}>Name</div>
                    <input
                      name="name"
                      type="text"
                      className={`${Style["comment_input"]} comment_input`}
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={Style["gererl-box"]}>
                    <div className={`${Style.form_title} form_title`}>Email</div>
                    <input
                      name="email"
                      type="text"
                      className={`${Style["comment_input"]} comment_input`}
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={`row ${Style["gererl-box"]}`}>
                    <div className="col-lg-5">
                      <div className={`${Style.form_title} form_title`}>Mobile</div>
                      <input
                        name="mobile"
                        type="number"
                        className={`${Style["comment_input"]} comment_input`}
                        required
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={`col-lg-7 ${Style["gererl-box"]}`}>
                      <div className={`${Style.form_title} form_title`}>Select Course</div>
                      <select
                        name="course"
                        className={`${Style.counter_input} counter_input counter_options`}
                        value={formData.course}
                        onChange={handleChange}
                      >
                        <option value="">Choose Course</option>
                        <option value="Course 1">Course 1</option>
                        <option value="Course 2">Course 2</option>
                        <option value="Course 3">Course 3</option>
                      </select>
                    </div>
                  </div>
                  <div className="py-2 text-start d-block">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} />
                  </div>
                  <div className="mb-2">
                    <button type="submit" className={`${Style.general_button} trans_200`}>
                      Find Best University
                    </button>
                  </div>
                  <small>Only certified University Guroo expert will assist you</small>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
