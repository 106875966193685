import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import counter1 from "../assets/counter-1.jpg"
import moneyug from "../assets/money-ug.gif"

export default function Counter() {
  const alluniversity = useSelector((state) => state.allUniversity?.allUniversity)
  const [isVerified, setIsVerified] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");

  const handleRecaptcha = (value) => {
    const timeout = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("ReCAPTCHA Timeout")), 5000) // 5 seconds
    );
  
    Promise.race([
      new Promise((resolve) => resolve(!!value)),
      timeout,
    ])
      .then((result) => setIsVerified(result))
      .catch((error) => console.error("ReCAPTCHA Error:", error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isVerified) {
      console.log("Form submitted", { selectedUniversity, courseName });
    } else {
      alert("Please verify reCAPTCHA");
    }
  };
  return (
    <>
      <div className="counter">
        <div
          className="counter_background"
          style={{ backgroundImage: `url(${counter1})` }}
        />
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="gif-wrap">
              <img className="counter-money" src={moneyug} alt="" />
            </div>
          </div>
          <div
            className="row counter_form"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="fill_height">
              <div className="col fill_height">
                <div className="counter_form_title">Check Course Fee</div>
                <form
                  className="counter_form_content d-flex flex-column align-items-center justify-content-center"
                  onSubmit={handleSubmit}
                >
                  <select
                    name="university_select"
                    id="university_select"
                    className="counter_input counter_options"
                    value={selectedUniversity}
                    onChange={(e) => setSelectedUniversity(e.target.value)}
                  >
                    <option>All University</option>
                    {
                      alluniversity?.map((item, index) => (
                        <option key={index} className="courseSelect" value={item.name}>{item.name}</option>

                      ))
                    }
                  </select>
                  <div className="morecourseShow">
                    <input
                      placeholder="Type Courses"
                      type="text"
                      value={courseName}
                      onChange={(e) => setCourseName(e.target.value)}
                    />
                  </div>
                  <div className="py-2 text-start d-block">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} />
                  </div>
                  <button type="submit" className="counter_form_button">
                    submit now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
