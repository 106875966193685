import React, { useEffect, useState } from "react";
import CommonLayout from "../layouts/CommonLayout";
import TopForm from "../components/GeneralCourse/TopForm";
import CourseValue from "../components/GeneralCourse/CourseValue";
import SpecializationTable from "../components/GeneralCourse/SpecializationTable";
import SubHeader from "../components/CourseComponents/SubHeader";
import KeyHighlights from "../components/GeneralCourse/KeyHighlights";
import Subjects from "../components/GeneralCourse/Subjects";
import Eligibility from "../components/GeneralCourse/Eligibility";
import ProgramFee from "../components/GeneralCourse/ProgramFee";
import AdmissionProcedure from "../components/GeneralCourse/AdmissionProcedure";
import TopSpecialization from "../components/GeneralCourse/TopSpecialization";
import LoanEmi from "../components/GeneralCourse/LoanEmi";
import WorthIt from "../components/GeneralCourse/WorthIt";
import CareerScope from "../components/GeneralCourse/CareerScope";
import Faqs from "../components/CourseComponents/Faqs";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import uniqid from "uniqid";
import { apiBaseUrl } from "../config";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setGsc } from "../reducer/generalSpecializationSlice";
import UniversityLayout from "../components/GeneralCourse/UniversityLayout";

function GeneralCourse() {
    const [genspeCourseDetailsData, setGenspeCourseDetailsData] = useState({});
    const { courseName } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
  
    const fetchGeneralCourse = async () => {
      try {
        const res = await fetch(`${apiBaseUrl}general-course-details/${courseName}`);
        const data = await res.json();
       
        if (data) {
          dispatch(setGsc(data));
          setGenspeCourseDetailsData(data);
        } else {
          console.error("Invalid response format", data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);  
      }
    };
  
    useEffect(() => {
      if (courseName) {
        fetchGeneralCourse();
      }
    }, [courseName]);
  
    useEffect(() => {
      fetchGeneralCourse();
    }, [courseName]);
  
    const subheaderContent = [
      { title: "overview", link: "#overview" },
      { title: "Key Highlights", link: "#key-highlights" },
      { title: "Subject/Syllabus", link: "#subject" },
      { title: "Eligibility & Duration", link: "#eligibility" },
      { title: "Program Fees", link: "#program_fee" },
      { title: " Admission Procedure", link: "#Admission_procedure" },
      { title: "Top Specialization", link: "#Top_specialization" },
      { title: "Education Loan/EMIs", link: "#Loan_emi" },
      { title: "Worth It?", link: "#Worth-it" },
      { title: "Career Scope", link: "#Career_Scope" },
      { title: "Faq's", link: "#Faqs" },
    ];
  
    if (loading) {
      return <div>Loading...</div>;  // Optionally, show a loading spinner here
    }
  
  
  
    return (
      <CommonLayout>
          <>
            <TopForm key={uniqid()} data={genspeCourseDetailsData[1]} />
            <CourseValue key={uniqid()} data={genspeCourseDetailsData[1]} />
            <SpecializationTable key={uniqid()} data={genspeCourseDetailsData[1]}/>
            <SubHeader subheaderContent={subheaderContent} />
            <KeyHighlights key={uniqid()} data={genspeCourseDetailsData[1]} />
            <Subjects key={uniqid()} data={genspeCourseDetailsData[1]} />
            <Eligibility key={uniqid()} data={genspeCourseDetailsData[1]} />
            <ProgramFee key={uniqid()} data={genspeCourseDetailsData[1]} />
            <AdmissionProcedure key={uniqid()} data={genspeCourseDetailsData[1]} />
            <TopSpecialization key={uniqid()} data={genspeCourseDetailsData[1]?.top_specializationDet} />
            <LoanEmi key={uniqid()} data={genspeCourseDetailsData[1]?.education_loan_emi} />
            <WorthIt key={uniqid()} data={genspeCourseDetailsData[1]?.worth_it} />
            <CareerScope key={uniqid()} data={genspeCourseDetailsData[1]?.career_scope} />
            <Faqs key={uniqid()} data={genspeCourseDetailsData[1]?.FaqDet} />
            <ExpertAdvice getModal="#modalLoginForm2" />
            <InNews />
            <UniversityLayout key={uniqid()} data={genspeCourseDetailsData[1]?.UnivercityDet} />
            <NewsLetter />
          </>
      
      </CommonLayout>
    );
}

export default GeneralCourse