import React, { useEffect, useState } from "react";
import { apiBaseUrl } from "../config";
import FeaturedCarousel from "./CustomCarousel/FeaturedCarousel";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeaturedUniversity,
  // setDistanceUni,
  // setOnlineUni,
  // setPartUni,
} from "../reducer/featuredUniversitySlice";


function FeaturedUniversity() {
  const dispatch = useDispatch();
  const [PartUni, setPartUni] = useState([]);
  const [OnlineUni, setOnlineUni] = useState([]);
  const [DistanceUni, setDistanceUni] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabFeed, setTabFeed] = useState("popular_univercity");
  
  const fetchFeaturedUniversity = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}featured-univercity`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          // setUniversity(data);
          dispatch(getFeaturedUniversity(data && data));
          setPartUni(data && data.popular_univercity);
          setOnlineUni(data && data.online_univercity);
          setDistanceUni(data && data.distance_univercity);
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching banner image:", error);
      });
  };

  useEffect(() => {
    fetchFeaturedUniversity();
  }, [apiBaseUrl]);

  return (
    <>
      <div className="Featured-University section">
        <div className="container" data-aos="fade-in">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h2 className="section_title">Featured University</h2>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-delay={100}>
            {/* Nav tabs */}
            <div className="card">
              <ul
                id="myTab"
                role="tablist"
                className="nav nav-tabs nav-pills bg-light border-0 rounded-nav"
              >
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div
                    id="popular-tab"
                    onClick={() => {
                      setTabFeed("popular_univercity");
                    }}
                    role="tab"
                    className={
                      tabFeed === "popular_univercity"
                        ? "nav-link border-0 text-uppercase font-weight-bold active"
                        : "nav-link border-0 text-uppercase font-weight-bold "
                    }
                  >
                    Popular
                  </div>
                </li>
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div
                    id="online-tab"
                    onClick={() => {
                      setTabFeed("online_univercity");
                    }}
                    role="tab"
                    className={
                      tabFeed === "online_univercity"
                        ? "nav-link border-0 text-uppercase font-weight-bold active"
                        : "nav-link border-0 text-uppercase font-weight-bold "
                    }
                  >
                    Online
                  </div>
                </li>
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div
                    id="distance-tab"
                    onClick={() => {
                      setTabFeed("distance_univercity");
                    }}
                    role="tab"
                    className={
                      tabFeed === "distance_univercity"
                        ? "nav-link border-0 text-uppercase font-weight-bold active"
                        : "nav-link border-0 text-uppercase font-weight-bold "
                    }
                  >
                    Distance
                  </div>
                </li>
              </ul>
              <div id="myTabContent" className="tab-content">
                {tabFeed === "popular_univercity" && (
                  <div
                    id="popular"
                    role="tabpanel"
                    aria-labelledby="popular-tab"
                    className="tab-pane fade show active"
                    data-aos="fade-in"
                    data-aos-delay={100}
                  >
                    {loading ? (
                      <h3>Loading...</h3>
                    ) : (
                      <div>
                          <FeaturedCarousel key={tabFeed} list={PartUni} />
                      </div>
                    )}
                  </div>
                )}
                {tabFeed === "online_univercity" && (
                  <div
                    id="online"
                    role="tabpanel"
                    aria-labelledby="online-tab"
                    className="tab-pane fade show active"
                    data-aos="fade-in"
                    data-aos-delay={100}
                  >
                    {loading ? (
                      <h3>Loading...</h3>
                    ) : (
                      <FeaturedCarousel key={tabFeed} list={OnlineUni} />
                    )}
                  </div>
                )}
                {tabFeed === "distance_univercity" && (
                  <div
                    id="distance"
                    role="tabpanel"
                    aria-labelledby="distance-tab"
                    className="tab-pane fade show active"
                    data-aos="fade-in"
                    data-aos-delay={100}
                  >
                    {loading ? (
                      <h3>Loading...</h3>
                    ) : (
                      <FeaturedCarousel key={tabFeed} list={DistanceUni} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturedUniversity;
