import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import owlStyle from "../../assets/styles/ModuleStyle/customOwl.module.css";
import { baseUrl } from "../../config";
import { Link } from "react-router-dom";

export default function UniversityLayout({data}) {
  const [loading, setLoading] = useState(false);
  
  return (
    <>
      <div id="Top_UG_university" className="Top_university section">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col pb-4">
              <div className="section_title text-left">
                <h2 className="section_title">Top Universities in India</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {loading ? (
              <h3>Loading...</h3>
            ) : (
              <OwlCarousel
                id="top_university"
                className="owl-carousel owl-theme row"
                loop
                nav
                dots={false}
                stageOuterClass={owlStyle.owlStageOuterSpec}
                navText={[
                  "<i class='fa fa-chevron-left'></i>",
                  "<i class='fa fa-chevron-right'></i>",
                ]}
                navContainerClass={owlStyle.containerNav}
                navClass={["navPrev", "navNext"]}
                items={3}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                }}
              >
                {data && data?.map((item, index) => (
                    <div
                    key={item.established + index}
                    className="thumbnail item col fea_university_col"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <div className="fev-colleges">
                    <div className="fea-university_year text-right ml-auto">
                        {item.established}
                      </div>
                      <div className="fea-university_image">
                        <img src={`${baseUrl}${item.logo}`} alt="" />
                      </div>
                      
                      <div className="fea-university_footer">
                      <h3 className="fea-university_title" style={{textAlign:'left'}}>
                          <Link to="#">{item.course_name}</Link>
                        </h3>
                        <div className="fea-university_footer_content d-flex flex-row align-items-start justify-content-between border-0 p-0 m-0 ">
                          <div className="fea-university_info ">
                            <div className="fea-university_state m-0 p-0">
                              ( {item.state} )
                            </div>
                            <div className="fea-university_text"  style={{textAlign:'left', color:'gold', fontWeight:'600', fontSize:'15px'}}>
                              {item.univercity_name}
                            </div>
                          </div>
                        </div>
                        <div className="fea-university-button" style={{textAlign:'left'}}>
                          <Link
                            className="btn fea-button px-0" 
                            style={{fontWeight:'600', color:'#009ee3'}}
                            to={`/university/${item.course_name}`}
                          >
                            View More 
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
